(function() {
  window.Photo = (function() {
    function Photo() {}

    Photo.setup = function() {
      return Photo.decorate();
    };

    Photo.decorate = function() {
      $(".js-photos").find(".js-zoom").zoom();
      $(".js-photos").on('click', '.js-photo-reject', function(e) {
        var $link, $reasons;
        e.preventDefault();
        $link = $(this);
        $reasons = $("#photo_reasons").addClass("js-photos-reasons");
        $reasons.find("form").prop("action", $link.prop("href"));
        $reasons.find("input").prop('checked', '');
        $reasons.find("input[type='submit']").prop('disabled', '');
        return $.magnificPopup.open({
          items: {
            src: $reasons,
            type: "inline"
          },
          cache: false
        });
      });
      $(".js-photos").on('click', '.js-photo-approve', function(e) {
        var $link;
        e.preventDefault();
        $link = $(this);
        return $.ajax({
          method: "GET",
          url: $link.attr('href'),
          dataType: "json",
          success: function(data) {
            return $(".photo-card[data-photo=" + data.id + "]").remove();
          }
        });
      });
      return $(document).on('submit', '.js-photos-reasons form', function(e) {
        var $form;
        $form = $(this);
        $.ajax({
          method: "POST",
          url: $form.attr('action'),
          dataType: "json",
          data: $form.serialize(),
          success: function(data) {
            $(".photo-card[data-photo=" + data.id + "]").remove();
            return $.magnificPopup.close();
          }
        });
        e.preventDefault();
        return false;
      });
    };

    return Photo;

  })();

}).call(this);