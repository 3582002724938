(function() {
  window.ExtraSearch = (function() {
    function ExtraSearch() {}

    ExtraSearch.map = null;

    ExtraSearch.dropMarker = null;

    ExtraSearch.dropRadius = null;

    ExtraSearch.setup = function() {
      return ExtraSearch.decorate();
    };

    ExtraSearch.openGroups = function() {
      var $base;
      $base = $(".js-extra-search-filter");
      return $base.find(".filter-group").each(function() {
        if ($(this).find(".filter-sub-group").find(".active").length > 0) {
          $(this).find(".filter-sub-group").addClass("active");
          return $(this).find(".filter-heading").addClass("active");
        }
      });
    };

    ExtraSearch.toggleFilter = function(e) {
      e.preventDefault();
      return $(this).closest(".filter").toggleClass("active");
    };

    ExtraSearch.toggleFilterHeading = function(e) {
      e.preventDefault();
      $(this).closest(".filter").toggleClass("active");
      return $(this).closest(".filter-group").find(".filter-sub-group").toggleClass("active");
    };

    ExtraSearch.setLocation = function(e) {
      var $base, lat, lng, radius;
      e.preventDefault();
      $base = $(".js-extra-search-filter");
      radius = ExtraSearch.dropRadius.getRadius();
      lat = ExtraSearch.dropRadius.getCenter().lat();
      lng = ExtraSearch.dropRadius.getCenter().lng();
      $base.find(".js-lat").val(lat);
      $base.find(".js-radius").val(radius);
      $base.find(".js-lng").val(lng);
      return $base.find(".js-location-form").submit();
    };

    ExtraSearch.searchLocation = function(e) {
      var geocoder, q;
      e.preventDefault();
      q = $(".js-extra-search-location-input").val();
      geocoder = new google.maps.Geocoder();
      return geocoder.geocode({
        address: q
      }, function(results, status) {
        if (status === 'OK') {
          ExtraSearch.map.setCenter(results[0].geometry.location);
          return ExtraSearch.dropRadius.setCenter(results[0].geometry.location);
        }
      });
    };

    ExtraSearch.locationFancyBox = function() {
      return $(".js-extra-search-filter").find(".js-location-fancybox").fancybox({
        width: "90%",
        afterShow: function() {
          var $base, lat, lng, radius;
          $base = $(".js-extra-search-filter");
          lat = parseFloat($base.find(".js-lat").val());
          lng = parseFloat($base.find(".js-lng").val());
          radius = parseFloat($base.find(".js-radius").val());
          if (isNaN(lat)) {
            lng = -5.930119999999988;
          }
          if (isNaN(lat)) {
            lat = 54.59728500000001;
          }
          if (isNaN(radius)) {
            radius = 10 * 1609.34;
          }
          if (ExtraSearch.map == null) {
            ExtraSearch.map = new google.maps.Map($(".js-extra-search-map").get(0), {
              zoom: 9,
              center: {
                lat: lat,
                lng: lng
              }
            });
          }
          if (ExtraSearch.dropRadius == null) {
            ExtraSearch.dropRadius = new google.maps.Circle({
              center: {
                lat: lat,
                lng: lng
              },
              map: ExtraSearch.map,
              radius: radius,
              strokeColor: "#ff0000",
              strokeWeight: 2,
              strokeOpacity: 0.6,
              fillColor: "#ff0000",
              fillOpacity: 0.2,
              zIndex: 1,
              editable: true
            });
          }
          ExtraSearch.map.setCenter({
            lat: lat,
            lng: lng
          });
          ExtraSearch.dropRadius.setCenter({
            lat: lat,
            lng: lng
          });
          return loadExtras(ExtraSearch.map);
        }
      });
    };

    ExtraSearch.displaySmallMap = function() {
      var $base, lat, lng, radius, smallMap, smallRadius;
      if ($(".js-extra-search-filter").find(".js-small-map").length > 0) {
        $base = $(".js-extra-search-filter");
        lat = parseFloat($base.find(".js-lat").val());
        lng = parseFloat($base.find(".js-lng").val());
        radius = parseFloat($base.find(".js-radius").val());
        if (!isNaN(lat)) {
          $(".js-location-filter").addClass("active");
          $(".js-small-map").show();
          smallMap = new google.maps.Map($(".js-small-map").get(0), {
            zoom: 8,
            disableDefaultUI: true,
            draggable: false,
            panControl: false,
            mapTypeControl: false,
            rotateControl: false,
            scaleControl: false,
            zoomControl: false,
            center: {
              lng: lng,
              lat: lat
            }
          });
          smallMap.addListener('click', function(e) {
            return $(".js-location-fancybox").trigger('click');
          });
          smallRadius = new google.maps.Circle({
            center: {
              lng: lng,
              lat: lat
            },
            map: smallMap,
            radius: radius,
            strokeColor: "#ff0000",
            strokeWeight: 2,
            strokeOpacity: 0.6,
            fillColor: "#ff0000",
            fillOpacity: 0.2,
            zIndex: 1
          });
          return smallRadius.addListener('click', function(e) {
            return $(".js-location-fancybox").trigger('click');
          });
        }
      }
    };

    ExtraSearch.decorate = function() {
      $(".js-extra-search-filter").on('click', '.js-toggle-filter', ExtraSearch.toggleFilter);
      $(".js-extra-search-filter").on('click', '.js-toggle-filter-heading', ExtraSearch.toggleFilterHeading);
      $(document).on('click', '.js-extra-search-set-location', ExtraSearch.setLocation);
      $(document).on('submit', '.js-extra-search-search-location', ExtraSearch.searchLocation);
      ExtraSearch.displaySmallMap();
      ExtraSearch.locationFancyBox();
      return ExtraSearch.openGroups();
    };

    return ExtraSearch;

  })();

}).call(this);