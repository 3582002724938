(function() {
  jQuery(function() {
    return $(function() {
      $(document).on('click', '.js-availability-confirm-modal', function(e) {
        e.preventDefault();
        return $("body").addClass("show-confirm-modal");
      });
      $(document).on('click', '.js-availability-not-available-modal', function(e) {
        e.preventDefault();
        return $("body").addClass("show-not-available-modal");
      });
      $(document).on('click', '.js-availability-close-modal', function(e) {
        e.preventDefault();
        $("body").removeClass("show-confirm-modal");
        $("body").removeClass("show-not-available-modal");
        return $("body").removeClass("show-release-modal");
      });
      return $(document).on('change', '.js-availability-callback', function(e) {
        if ($(this).is(":checked")) {
          $(".js-accepted").hide();
          return $(".js-callback").show();
        } else {
          $(".js-accepted").show();
          return $(".js-callback").hide();
        }
      });
    });
  });

}).call(this);