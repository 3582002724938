(function() {
  window.CallList = (function() {
    function CallList() {}

    CallList.setup = function() {
      return CallList.decorate();
    };

    CallList.decorate = function() {
      $(".js-call-list-jump").select2();
      $(document).on('change', '.js-call-list-jump', function(e) {
        return window.location = $(this).val();
      });
      $(document).on('click', '.js-call-list', function(e) {
        e.preventDefault();
        $(this).html("Working...").removeClass("js-call-list");
        return $.ajax({
          url: $(this).attr('href'),
          type: 'GET'
        });
      });
      return $(document).on('click', '.js-call-list-expand', function(e) {
        var id;
        e.preventDefault();
        id = $(this).data('id');
        if ($("#call_list_" + id + "_expand").length > 0) {
          $("#call_list_" + id + "_expand").remove();
          return $("#call_list_" + id).removeClass("expanded");
        } else {
          return $.ajax({
            url: $(this).attr('href'),
            type: 'GET',
            success: function(data) {
              $("#call_list_" + id).after(data);
              $("#call_list_" + id).addClass("expanded");
              return ExtraMessage.scrollToBottom();
            }
          });
        }
      });
    };

    return CallList;

  })();

}).call(this);