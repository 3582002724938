(function() {
  window.Message = (function() {
    function Message() {}

    Message.setup = function() {
      return Message.decorate();
    };

    Message.decorate = function() {
      return $(".js-message-template-apply").on('click', function(e) {
        var id;
        e.preventDefault();
        id = $(".js-message-template-select").val();
        return $.ajax({
          url: "/admin/email_templates/" + id,
          dataType: "json",
          method: "get",
          success: function(data) {
            $("#message_subject").val(data.subject);
            return $("#message_body").froalaEditor('html.set', data.body);
          }
        });
      });
    };

    return Message;

  })();

}).call(this);