(function() {
  window.ExtraMessage = (function() {
    function ExtraMessage() {}

    ExtraMessage.setup = function() {
      return ExtraMessage.decorate();
    };

    ExtraMessage.scrollToBottom = function() {
      return $(".js-extra-message-list").scrollTop(100000);
    };

    ExtraMessage.decorate = function() {
      ExtraMessage.scrollToBottom();
      $(document).on('click', ".js-extra-message-canned", function(e) {
        var text;
        e.preventDefault();
        ExtraMessage.scrollToBottom();
        text = $(this).data("text");
        return $(this).closest(".message-block").find("input[name='body']").val(text);
      });
      return $(document).on('submit', ".js-extra-message-form", function(e) {
        var $form, $list;
        e.preventDefault();
        $form = $(this).find("form");
        $list = $(this).closest(".js-extra-messages").find(".js-extra-message-list");
        $.ajax({
          method: "POST",
          url: $form.attr('action'),
          data: $form.serialize(),
          dataType: "json",
          success: function(data) {
            var $meta, $msg, $wrap;
            $wrap = $("<div />").addClass("message-wrapper").addClass("outbound");
            $msg = $("<div />").addClass("message").html(data.subject);
            $meta = $("<div />").addClass("meta").html("Sending...");
            $wrap.append($msg).append($meta);
            $list.append($wrap);
            $form.find("input[type=text]").val('');
            return ExtraMessage.scrollToBottom();
          }
        });
        return false;
      });
    };

    return ExtraMessage;

  })();

}).call(this);