(function() {
  window.CastListExtras = (function() {
    function CastListExtras() {}

    CastListExtras.setup = function() {
      return CastListExtras.decorate();
    };

    CastListExtras.selectTab = function(tab) {
      $(".js-cast-list-extras").find(".js-table").attr('class', 'js-table table').addClass("on-" + tab);
      $(".js-cast-list-extras").find(".js-nav-tabs li").removeClass("active");
      $(".js-cast-list-extras").find(".js-nav-tabs li.col-" + tab).addClass("active");
      return window.location.hash = "#" + tab;
    };

    CastListExtras.updateBookingCounts = function() {
      return $(".js-cast-list-extras").find("th.col-availability[data-type=shooting]").each(function() {
        var $available, $booked, available, booked, date;
        available = 0;
        booked = 0;
        date = $(this).data('date');
        $available = $(this).find(".available");
        $booked = $(this).find(".booked");
        $(".js-cast-list-extras").find("td.col-availability[data-type=shooting]").each(function() {
          if ($(this).data('date') === date) {
            if ($(this).data('booking') === "available") {
              return available += 1;
            } else if ($(this).data('booking') === "booked") {
              return booked += 1;
            } else if ($(this).data('booking') === "locked") {
              return booked += 1;
            } else if ($(this).data('booking') === "paid") {
              return booked += 1;
            } else if ($(this).data('booking') === "invoiced") {
              return booked += 1;
            } else if ($(this).data('booking') === "released_for_payment") {
              return booked += 1;
            } else if ($(this).data('booking') === "released_for_invoice") {
              return booked += 1;
            } else if ($(this).data('booking') === "payment_sent") {
              return booked += 1;
            }
          }
        });
        $available.text(available);
        return $booked.text(booked);
      });
    };

    CastListExtras.toggleExtra = function(extra) {
      $(".js-cast-list-extras").find(".js-extra[data-extra=" + extra + "]").toggleClass("selected");
      return CastListExtras.updateToolbar();
    };

    CastListExtras.updateToolbar = function() {
      var bookings, extras;
      extras = _.map($(".js-cast-list-extras").find(".selected"), function(e) {
        return $(e).data("extra");
      });
      bookings = _.map($(".js-cast-list-extras").find(".selected"), function(e) {
        return $(e).data("booking");
      });
      $(".js-cast-list-extras").find(".js-bulk-extra-ids").val(extras.join(","));
      $(".js-cast-list-extras").find(".js-bulk-booking-ids").val(bookings.join(","));
      $(".js-cast-list-extras").find(".js-extra-count").html(extras.length);
      if (extras.length > 0) {
        return $(".js-cast-list-extras").find(".js-action").removeClass("disabled");
      } else {
        return $(".js-cast-list-extras").find(".js-action").addClass("disabled");
      }
    };

    CastListExtras.toggleTag = function(tag) {
      return $(".js-cast-list-extras").find(".js-tag[data-tag=" + tag + "]").toggleClass("btn-primary");
    };

    CastListExtras.updateBooking = function($link) {
      return $.ajax({
        method: "GET",
        url: $link.prop("href"),
        dataType: "html",
        success: function(data) {
          var $data, $td, $tr, date, extra, type;
          $td = $link.closest(".js-booking");
          $data = $(data);
          extra = $data.data("extra");
          type = $data.data("type");
          date = $data.data("date");
          $tr = $(".js-extra[data-extra=" + extra + "]");
          $tr.find(".js-booking[data-date=" + date + "]").each(function() {
            if ($(this).data('type') === type) {
              return $(this).replaceWith($(data));
            }
          });
          return CastListExtras.updateBookingCounts();
        }
      });
    };

    CastListExtras.replaceRow = function($tr, html) {
      $tr.html(html);
      $('.js-save-select').select2({
        theme: "bootstrap",
        width: "100%"
      });
      CastListExtras.updateBookingCounts();
      return CastListExtras.decorateTime();
    };

    CastListExtras.decorateTime = function() {
      return $('input.js-time').timepicker({
        timeFormat: "HH:mm",
        minTime: '00:00',
        maxTime: '23:59',
        startTime: '00:00',
        dynamic: false
      });
    };

    CastListExtras.decorate = function() {
      if ($(".js-cast-list-extras").length === 0) {
        return;
      }
      CastListExtras.updateBookingCounts();
      $(".js-cast-list-extras").find(".js-zoom").zoom();
      $(document).on('click', ".js-fee-override", function(e) {
        if ($(this).is(":checked")) {
          return $(this).closest("form").find(".js-fee-override-inputs").show();
        } else {
          return $(this).closest("form").find(".js-fee-override-inputs").hide();
        }
      });
      $(".js-cast-list-extras").on('click', ".js-existing-value", function(e) {
        var $input, $sel, $td;
        e.preventDefault();
        $td = $(this).closest("td");
        $input = $td.find("input");
        $sel = $td.find("select");
        if ($input.length > 0) {
          $input.val($(this).data("value"));
          $td.find("button").trigger('click');
        }
        if ($sel.length > 0) {
          $sel.val($(this).data("value"));
          return $sel.trigger('change');
        }
      });
      $(document).on('submit', '.js-booking-paid-form form', function(e) {
        var $form, extra;
        e.preventDefault();
        $form = $(this);
        extra = $(this).closest(".js-booking-paid-form").data('extra');
        $.ajax({
          method: "POST",
          url: $form.prop("action"),
          data: $form.serialize(),
          dataType: "html",
          success: function(data) {
            WebuiPopovers.hideAll();
            return CastListExtras.replaceRow($("tr[data-extra=" + extra + "]"), data);
          }
        });
        return false;
      });
      $(".js-cast-list-extras").on('click', ".js-state-toggle", function(e) {
        var $link, $tr;
        e.preventDefault();
        if ($(this).data('action') === "mark_as_paid") {
          $link = $(this);
          WebuiPopovers.hideAll();
          $(this).webuiPopover({
            width: 300,
            trigger: "manual",
            url: $link.prop('href'),
            type: "async",
            title: "Details",
            closeable: true,
            cache: false
          });
          return $(this).webuiPopover('show');
        } else {
          $tr = $(this).closest("tr");
          $link = $(this);
          return $.ajax({
            url: $link.prop('href'),
            method: "POST",
            dataType: "html",
            success: function(data) {
              return CastListExtras.replaceRow($tr, data);
            }
          });
        }
      });
      $(".js-cast-list-extras").find(".js-save-select").select2({
        theme: "bootstrap",
        width: "100%"
      });
      $(".js-cast-list-extras").on('change', ".js-save-select", function(e) {
        var $sel, $td, booking, value, subtab;
        booking = $(this).closest(".js-extra").data('booking');
        value = $(this).val();
        $sel = $(this);
        $td = $(this).closest("td");
        subtab = $(this).data('subtab');
        return $.ajax({
          url: "/admin/api/bookings/" + booking + "/inline_update",
          method: "POST",
          data: {
            field: $sel.prop("name"),
            value: value,
            subtab: subtab
          },
          dataType: "html",
          success: function(data) {
            CastListExtras.replaceRow($td.closest("tr"), data);
            return toastr.success("Saved!");
          },
          error: function() {
            return toastr.error("There was a problem saving that change.");
          }
        });
      });
      $(".js-cast-list-extras").on('click', '.js-save-value', function(e) {
        var $input, $td, booking, subtab;
        e.preventDefault();
        $td = $(this).closest("td");
        booking = $(this).closest(".js-extra").data('booking');
        $input = $td.find("input");
        subtab = $(this).data('subtab');
        return $.ajax({
          url: "/admin/api/bookings/" + booking + "/inline_update",
          method: "POST",
          data: {
            field: $input.prop("name"),
            value: $input.val(),
            subtab: subtab
          },
          dataType: "html",
          success: function(data) {
            CastListExtras.replaceRow($td.closest("tr"), data);
            return toastr.success("Saved!");
          },
          error: function() {
            return toastr.error("There was a problem saving that change.");
          }
        });
      });
      $(".js-cast-list-extras").on("submit", ".js-list-quick-search", function(e) {
        var $form;
        e.preventDefault();
        $form = $(this);
        $.ajax({
          url: $form.prop('action'),
          method: "GET",
          data: $form.serialize(),
          dataType: "html",
          success: function(data) {
            return $(".js-quick-search-results").html(data);
          }
        });
        return false;
      });
      $(".js-cast-list-extras").on('submit', '.js-fee-form form', function(e) {
        var $form;
        e.preventDefault();
        $form = $(this);
        $.ajax({
          url: $form.prop('action'),
          method: "POST",
          data: $form.serialize(),
          dataType: "html",
          success: function(data) {
            return CastListExtras.replaceRow($form.closest("tr"), data);
          }
        });
        return false;
      });
      $(".js-cast-list-extras").on('click', '.js-action', function(e) {
        var $reasons, ids, output;
        e.preventDefault();
        if ($(".js-cast-list-extras").find(".selected").length === 0) {
          alert("Please select one or more extras");
          return;
        }
        if ($(this).hasClass("js-action-remove")) {
          output = confirm($(this).data('confirm'));
          if (!output) {
            return;
          }
        }
        $(".js-cast-list-extras").find(".js-commit").val($(this).data('commit'));
        if ($(this).data('type') === "pdf") {
          $reasons = $("#pdf_options");
          $.magnificPopup.open({
            items: {
              src: $reasons,
              type: "inline"
            },
            cache: false
          });
        } else if ($(this).data('type') === "set") {
          $reasons = $("#set_options");
          ids = $(".js-cast-list-extras").find(".js-bulk-booking-ids").val();
          $(".js-bulk-set-ids").val(ids);
          $(".js-bulk-set-title").html("Update " + (ids.split(',').length) + " extras");
          $.magnificPopup.open({
            items: {
              src: $reasons,
              type: "inline"
            },
            cache: false
          });
        } else {
          $(this).closest("form").submit();
        }
      });
      $(document).on('click', ".js-generate-pdf", function(e) {
        var fields;
        e.preventDefault();
        fields = [];
        $(this).closest(".js-pdf-options").find("input:checked").each(function() {
          return fields.push($(this).val());
        });
        $(".js-fields").val(fields);
        $(".js-fields").get(0).closest("form").submit();
        return $.magnificPopup.close();
      });
      $(document).on('click', '.js-booking-action-book-direct', function(e) {
        var $link;
        e.preventDefault();
        $link = $(this);
        WebuiPopovers.hideAll();
        $(this).webuiPopover({
          width: 300,
          trigger: "manual",
          url: $link.prop('href'),
          type: "async",
          title: "Details",
          closeable: true,
          cache: false,
          async: {
            success: function(data) {
              return CastListExtras.decorateTime();
            }
          }
        });
        $(this).webuiPopover('show');
        return e.preventPropagation();
      });
      $(document).on('click', '.js-booking-action-book', function(e) {
        var $link, $tr, date, extra, type;
        e.preventDefault();
        $link = $(this);
        extra = $link.closest(".list-group").data("extra");
        type = $link.closest(".list-group").data("type");
        date = $link.closest(".list-group").data("date");
        $tr = $(".js-extra[data-extra=" + extra + "]");
        return $tr.find(".js-booking[data-date=" + date + "]").each(function() {
          if ($(this).data('type') === type) {
            WebuiPopovers.hideAll();
            $(this).webuiPopover({
              width: 300,
              trigger: "manual",
              url: $link.prop('href'),
              type: "async",
              title: "Details",
              closeable: true,
              cache: false,
              async: {
                success: function(data) {
                  return CastListExtras.decorateTime();
                }
              }
            });
            return $(this).webuiPopover('show');
          }
        });
      });
      $(document).on('click', '.js-booking-action', function(e) {
        e.preventDefault();
        WebuiPopovers.hideAll();
        CastListExtras.updateBooking($(this));
        return e.stopPropagation();
      });
      $(document).on('click', '.js-booking-action-note', function(e) {
        var $link, $tr, date, extra, type;
        e.preventDefault();
        $link = $(this);
        extra = $link.closest(".list-group").data("extra");
        type = $link.closest(".list-group").data("type");
        date = $link.closest(".list-group").data("date");
        $tr = $(".js-extra[data-extra=" + extra + "]");
        return $tr.find(".js-booking[data-date=" + date + "]").each(function() {
          if ($(this).data('type') === type) {
            WebuiPopovers.hideAll();
            $(this).webuiPopover({
              width: 300,
              trigger: "manual",
              url: $link.prop('href'),
              type: "async",
              title: "Note",
              closeable: true
            });
            return $(this).webuiPopover('show');
          }
        });
      });
      $(".js-cast-list-extras").on('click', '.js-action-bulk-set', function(e) {
        var $content;
        WebuiPopovers.hideAll();
        e.preventDefault();
        $content = $(this).closest(".booking-cell").find(".hide-me .list-group");
        $(this).webuiPopover({
          content: $content,
          padding: 0,
          width: 200,
          trigger: "manual",
          title: "Status",
          closeable: true,
          cache: false
        });
        return $(this).webuiPopover('show');
      });
      $(".js-cast-list-extras").on('click', '.js-booking-btn', function(e) {
        var $content;
        WebuiPopovers.hideAll();
        e.preventDefault();
        $content = $(this).closest(".booking-cell").find(".hide-me .list-group");
        $(this).webuiPopover({
          content: $content,
          padding: 0,
          width: 200,
          trigger: "manual",
          title: "Status",
          closeable: true,
          cache: false
        });
        $(this).webuiPopover('show');
        return e.stopPropagation();
      });
      $(document).on('submit', '.js-booking-note-form form', function(e) {
        var $form;
        e.preventDefault();
        $form = $(this);
        $.ajax({
          url: $form.attr("action"),
          method: "POST",
          data: $form.serialize(),
          success: function(data) {
            var $tr, date, extra, type;
            WebuiPopovers.hideAll();
            extra = $form.closest(".js-booking-note-form").data("extra");
            type = $form.closest(".js-booking-note-form").data("type");
            date = $form.closest(".js-booking-note-form").data("date");
            $tr = $(".js-extra[data-extra=" + extra + "]");
            $tr.find(".js-booking[data-date=" + date + "]").each(function() {
              if ($(this).data('type') === type) {
                return $(this).replaceWith($(data));
              }
            });
            return CastListExtras.updateBookingCounts();
          }
        });
        return false;
      });
      if ((window.location.hash != null) && window.location.hash.length > 3) {
        CastListExtras.selectTab(window.location.hash.replace("#", ""));
      }
      $(".js-cast-list-extras").on('click', '.js-bulk-all', function(e) {
        e.preventDefault();
        $(".js-extra").addClass('selected');
        return CastListExtras.updateToolbar();
      });
      $(".js-cast-list-extras").on('click', '.js-bulk-none', function(e) {
        e.preventDefault();
        $(".js-extra").removeClass('selected');
        return CastListExtras.updateToolbar();
      });
      $(".js-cast-list-extras").on('click', '.js-bulk-shooting', function(e) {
        e.preventDefault();
        $(".js-extra").removeClass('selected');
        $(".js-booking[data-booking=booked]").each(function() {
          if ($(this).data("type") === "shooting") {
            return $(this).closest(".js-extra").addClass("selected");
          }
        });
        return CastListExtras.updateToolbar();
      });
      $(".js-cast-list-extras").on('click', '.js-bulk-fitting', function(e) {
        e.preventDefault();
        $(".js-extra").removeClass('selected');
        $(".js-booking[data-booking=booked]").each(function() {
          if ($(this).data("type") === "fitting") {
            return $(this).closest(".js-extra").addClass("selected");
          }
        });
        return CastListExtras.updateToolbar();
      });
      $(".js-cast-list-extras").on('click', '.js-bulk-other', function(e) {
        e.preventDefault();
        $(".js-extra").removeClass('selected');
        $(".js-booking[data-booking=booked]").each(function() {
          if ($(this).data("type") === "other") {
            return $(this).closest(".js-extra").addClass("selected");
          }
        });
        return CastListExtras.updateToolbar();
      });
      $(".js-cast-list-extras").on('click', '.js-bulk-quarantine', function(e) {
        e.preventDefault();
        $(".js-extra").removeClass('selected');
        $(".js-booking[data-booking=booked]").each(function() {
          if ($(this).data("type") === "quarantine") {
            return $(this).closest(".js-extra").addClass("selected");
          }
        });
        return CastListExtras.updateToolbar();
      });
      $(".js-cast-list-extras").on('click', '.js-nav-tabs a', function(e) {
        var tab;
        tab = $(this).data("tab");
        if (tab != null) {
          e.preventDefault();
          return CastListExtras.selectTab(tab);
        }
      });
      $(".js-cast-list-extras").on('click', 'td.col-select', function(e) {
        var extra;
        extra = $(this).closest("tr").data("extra");
        return CastListExtras.toggleExtra(extra);
      });
      return $(".js-cast-list-extras").on('click', '.js-tag', function(e) {
        var tag;
        e.preventDefault();
        tag = $(this).data("tag");
        CastListExtras.toggleTag(tag);
        return e.preventPropagation();
      });
    };

    return CastListExtras;

  })();

}).call(this);