(function() {
  window.ExtraSidebarScratch = (function() {
    function ExtraSidebarScratch() {}

    ExtraSidebarScratch.setup = function() {
      return ExtraSidebarScratch.decorate();
    };

    ExtraSidebarScratch.updateBadge = function() {
      var count;
      count = $(".js-sidebar-scratch").find(".js-extra").length;
      if (count === 0) {
        return $("#sidebar-scratch-badge").hide();
      } else {
        return $("#sidebar-scratch-badge").html(count).show();
      }
    };

    ExtraSidebarScratch.add = function(extra) {
      var $destroy, $extra, $link;
      $extra = $("<li />").addClass("list-item").addClass("js-extra").attr("data-extra", extra.id);
      $destroy = $("<div class='destroy js-destroy'><i class='fa fa-times'></i></div>");
      $link = $("<a />").html(extra.name).prop('href', extra.url);
      $extra.append($destroy).append($link);
      $(".js-sidebar-scratch").find(".js-extras").prepend($extra);
      return ExtraSidebarScratch.updateBadge();
    };

    ExtraSidebarScratch.destroy = function(id) {
      return $.ajax({
        method: "post",
        url: "/admin/api/cast_lists/scratch/toggle_extra?extra_id=" + id,
        dataType: "json",
        success: function() {
          return ExtraSidebarScratch.remove(id);
        }
      });
    };

    ExtraSidebarScratch.remove = function(id) {
      $(".js-sidebar-scratch").find(".js-extra[data-extra=" + id + "]").remove();
      $(".js-extra-card").find("[data-cast-list=scratch]").removeClass('active');
      ExtraSidebarScratch.updateBadge();
      return ExtraCard.updateBadges();
    };

    ExtraSidebarScratch.enableAddSelect = function() {
      $(".js-sidebar-scratch").find(".js-select").select2({
        theme: "bootstrap",
        width: "100%",
        placeholder: "Add to a cast list...",
        ajax: {
          url: "/admin/api/cast_lists/search",
          delay: 250,
          processResults: function(data, params) {
            return {
              results: data
            };
          }
        }
      });
      return $(".js-sidebar-scratch").find(".js-select-go").on('click', function(e) {
        var ids, val;
        e.preventDefault();
        val = $(".js-sidebar-scratch").find(".js-select").val();
        $(".js-sidebar-scratch").find(".js-select").val(0).trigger('change');
        ids = [];
        $(".js-sidebar-scratch").find("input:checked").each(function() {
          return ids.push($(this).val());
        });
        return $.ajax({
          method: "post",
          url: "/admin/api/cast_lists/" + val + "/add_scratch",
          data: {
            extra_ids: ids
          },
          dataType: "json",
          success: function(data) {
            return window.location = data.url;
          }
        });
      });
    };

    ExtraSidebarScratch.decorate = function() {
      ExtraSidebarScratch.enableAddSelect();
      ExtraSidebarScratch.updateBadge();
      return $(".js-sidebar-scratch").on('click', '.js-destroy', function(e) {
        var id;
        e.preventDefault();
        id = $(this).closest(".js-extra").data('extra');
        return ExtraSidebarScratch.destroy(id);
      });
    };

    return ExtraSidebarScratch;

  })();

}).call(this);