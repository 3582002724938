(function() {
  window.ExtraDateInput = (function() {
    function ExtraDateInput() {}

    ExtraDateInput.setup = function() {
      return ExtraDateInput.decorate();
    };

    ExtraDateInput.decorate = function() {
      $(".js-set-time").on('change', function(e) {
        var $target, date;
        e.preventDefault();
        $target = $("#" + ($(this).data('target')));
        console.log($(this).data('target'));
        date = $(this).val();
        if (date === 'now') {
          return $target.val(moment().format("DD MMM YYYY hh:mmA"));
        } else if (date === '0') {
          return $target.val('');
        } else {
          return $target.val(date);
        }
      });
      $(".js-datetime-input-std").each(function() {
        return $(this).datetimepicker({
          format: "DD/MM/YYYY hh:mmA",
          showClear: true,
          showClose: true,
          showTodayButton: true
        });
      });
      $(".js-datetime-input-clear").each(function() {
        return $(this).datetimepicker({
          format: "DD MMM YYYY hh:mmA",
          minDate: moment(),
          showClear: true,
          showClose: true,
          showTodayButton: true
        });
      });
      $(".js-date-input-dob").each(function() {
        return $(this).datepicker({
          format: "dd M yyyy",
          startView: 'decade',
          autoclose: true
        });
      });
      $(".js-date-input-std").each(function() {
        return $(this).datepicker({
          format: "dd M yyyy"
        });
      });
      $(".js-date-input-embed").each(function() {
        var $el, $target, target;
        target = $(this).data("target");
        $target = $("#" + target);
        $el = $(this);
        return $(this).datepicker({
          format: "yyyy-mm-dd",
          startDate: moment().format("YYYY-MM-DD")
        }).on('changeDate', function() {
          $target.val($el.datepicker('getFormattedDate'));
          return $target.closest("form").submit();
        });
      });
      $(".js-date-input").each(function() {
        var $field, $list, $picker;
        $picker = $(this).find(".js-date-picker");
        $field = $(this).find(".js-date-field");
        $list = $(this).find(".js-date-list");
        $picker.datepicker({
          format: 'yyyy-mm-dd',
          multidate: true
        }).on("changeDate", function() {
          var dates, rawDates;
          dates = _.sortBy($picker.datepicker('getDates'), function(a) {
            return a;
          });
          rawDates = _.map(dates, function(date) {
            return moment(date).format('YYYY-MM-DD');
          });
          $field.val(rawDates);
          $list.html("");
          return _.each(dates, function(date) {
            var $li;
            $li = $("<a />").addClass("list-group-item").addClass("js-date-selected-date").attr("data-date", moment(date).format('YYYY-MM-DD')).html("<i class='fa fa-close text-danger'></i> " + moment(date).format('dddd, Do MMMM YYYY'));
            return $list.append($li);
          });
        });
        return $picker.datepicker('setDates', $field.val().split(","));
      });
      $(document).on('click', 'a.js-date-clear', function(e) {
        var $input, $picker;
        e.preventDefault();
        $input = $(this).closest(".js-date-input");
        $picker = $input.find(".js-date-picker");
        $picker.val('');
        return $picker.datepicker('clearDates');
      });
      return $(document).on('click', 'a.js-date-selected-date', function(e) {
        var $input, $picker, dates, rawDates, thisDate;
        e.preventDefault();
        thisDate = $(this).data('date');
        $input = $(this).closest(".js-date-input");
        $picker = $input.find(".js-date-picker");
        dates = $picker.datepicker('getDates');
        rawDates = _.filter(_.map(dates, function(date) {
          return moment(date).format('YYYY-MM-DD');
        }), function(date) {
          return date !== thisDate;
        });
        return $picker.datepicker('setDates', rawDates);
      });
    };

    return ExtraDateInput;

  })();

}).call(this);