(function() {
  window.LiveSearch = (function() {
    function LiveSearch() {}

    LiveSearch.setup = function() {
      return LiveSearch.decorate();
    };

    LiveSearch.toggleFilter = function(e) {

      e.preventDefault();

      const url = $(this).attr("href");

      const filter = $(this).closest(".filter");
      const group = filter.find(".list-group");

      if (filter.hasClass("active")) {
        return filter.removeClass("active");
      } else {

        $.ajax({
          url: url,
          dataType: "html",
          success: function(data) {
            group.html(data);
          }
        });

        return filter.addClass("active");
      }
    };

    LiveSearch.decorate = function() {
      $(document).on('click', '.js-live-filter', LiveSearch.toggleFilter)

      $(".js-live-filter-load").each(function() {
        $(this).find(".js-live-filter").each(function() {
          $(this).trigger('click');
        })
      })

      return;
    };

    return LiveSearch;

  })();

}).call(this);