
(function() {
  window.ExtraCard = (function() {
    function ExtraCard() {}

    ExtraCard.setup = function() {
      return ExtraCard.decorate();
    };

    ExtraCard.updateBadges = function() {
      return $(".js-extra-card").each(function() {
        var count;
        count = $(this).find(".js-cast-lists").find(".active").length;
        if (count === 0) {
          return $(this).find(".js-badge").hide();
        } else {
          return $(this).find(".js-badge").html(count).show();
        }
      });
    };

    ExtraCard.addToQuickList = function(list) {
      var $link;
      $link = $("<a />").addClass("js-cast-list").addClass("list-group-item").html(list.name).attr('data-cast-list', list.id);
      $(".js-extra-card").each(function() {
        var $temp, extra;
        extra = $(this).data('extra');
        $temp = $link.clone();
        $temp.prop('href', "/admin/api/cast_lists/" + list.id + "/toggle_extra?extra_id=" + extra);
        if (list.extra_ids.indexOf(parseInt(extra)) >= 0) {
          $temp.addClass("active");
        }
        return $(this).find(".js-cast-lists").prepend($temp);
      });
      return ExtraCard.updateBadges();
    };

    ExtraCard.toggleQuickList = function(e) {
      var $list;
      e.preventDefault();
      $list = $(this);
      return $.ajax({
        method: "POST",
        url: $list.prop('href'),
        dataType: "json",
        success: function(data) {
          if (data.outcome === "remove") {
            $list.removeClass("active");
            if (data.cast_list === "scratch") {
              ExtraSidebarScratch.remove(data.extra.id);
            }
          } else if (data.outcome === "add") {
            $list.addClass("active");
            if (data.cast_list === "scratch") {
              ExtraSidebarScratch.add(data.extra);
            }
          }
          ExtraCard.updateBadges();
          $list.closest(".js-extra-card").removeClass("-list-mode");
          $(".js-sidebar-cast-lists").find(".js-list[data-cast-list=" + data.cast_list + "]").find(".js-badge").html(data.extras);
          return $(".js-sidebar-scratch-list").remove();
        }
      });
    };

    ExtraCard.enablePopover = function() {



      $(".js-extra-card").find(".js-popover-trigger").each(function() {
        
        
        let $el = $(this);

        $el.webuiPopover({
          url: $el.data('url'),
          type: "async",
          trigger: "manual",
          placement: "horizontal"
        })

        let timeout;
        
        $el.on("mouseenter", function() {
          timeout = setTimeout(function() {
            $el.webuiPopover("show");
          }, 500);
        });

        $el.on("mouseleave", function() {
          clearTimeout(timeout);
          $el.webuiPopover("hide"); 
        });


      });
    };

    ExtraCard.enableZoom = function() {
      return $(".js-extra-card").find(".js-zoom").zoom();
    };

    ExtraCard.showHideQuickList = function(e) {
      var $card;
      e.preventDefault();
      $card = $(this).closest(".js-extra-card");
      if (!$card.hasClass("-list-mode")) {
        $(".list-mode").removeClass("-list-mode");
      }
      $card.toggleClass("-list-mode");
      if ($card.hasClass("-list-mode")) {
        return $card.find(".js-cast-list-wrapper").animate({
          scrollTop: 1000
        }, 0);
      }
    };

    ExtraCard.decorate = function() {
      ExtraCard.enablePopover();
      ExtraCard.enableZoom();
      ExtraCard.updateBadges();
      $(document).on('click', '.js-extra-card .js-name', ExtraCard.showHideQuickList);
      $(document).on('click', '.js-extra-card .js-cast-list', ExtraCard.toggleQuickList);
      return $(document).on('click', '.js-sidebar-scratch-list', ExtraCard.toggleQuickList);
    };

    return ExtraCard;

  })();

}).call(this);