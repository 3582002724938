(function() {
  window.ExtraApproval = (function() {
    function ExtraApproval() {}

    ExtraApproval.setup = function() {
      return ExtraApproval.decorate();
    };

    ExtraApproval.decorate = function() {
      $(".js-extra-approval").find(".js-zoom").zoom();
      return $(".js-extra-approval").on('click', '.js-extra-approve', function(e) {
        var $link;
        e.preventDefault();
        $link = $(this);
        return $.ajax({
          method: "GET",
          url: $link.attr('href'),
          dataType: "json",
          success: function(data) {
            return $(".extra-card[data-extra=" + data.id + "]").remove();
          }
        });
      });
    };

    return ExtraApproval;

  })();

}).call(this);