(function() {
  window.ProductionRates = (function() {
    function ProductionRates() {}

    ProductionRates.setup = function() {
      return ProductionRates.decorate();
    };

    ProductionRates.decorate = function() {
      return $(document).on('click', '.js-production-rates-extra-rate-calc', function(e) {
        var $card, fee, pc, total;
        e.preventDefault();
        $card = $(this).closest(".js-rate-card");
        total = $card.find(".js-production-rates-total-fee").val();
        pc = $card.find(".js-production-rates-agency-pc").val();
        fee = parseFloat(total) * parseFloat(pc) / 100.0;
        return $card.find(".js-production-rates-extra-rate").val((parseFloat(total) - fee).toFixed(2));
      });
    };

    $(document).on('click', '.js-production-rates-holiday-calc', function(e) {
      var $card, fee, total;
      e.preventDefault();
      $card = $(this).closest(".js-rate-card");
      total = $card.find(".js-production-rates-total-fee").val();
      fee = parseFloat(total) / 1.1077;
      return $card.find(".js-production-rates-holiday").val((parseFloat(total) - fee).toFixed(2));
    });

    return ProductionRates;

  })();

}).call(this);