(function() {
  window.ExtraSidebarCastList = (function() {
    function ExtraSidebarCastList() {}

    ExtraSidebarCastList.setup = function() {
      return ExtraSidebarCastList.decorate();
    };

    ExtraSidebarCastList.updateBadge = function() {
      var count;
      count = $(".js-sidebar-cast-lists").find(".js-list").length;
      if (count === 0) {
        return $("#sidebar-cast-lists-badge").hide();
      } else {
        return $("#sidebar-cast-lists-badge").html(count).show();
      }
    };

    ExtraSidebarCastList.toggleEditMode = function(e) {
      e.preventDefault();
      return $(".js-sidebar-cast-lists").toggleClass("-edit-mode");
    };

    ExtraSidebarCastList.enableSort = function() {
      var $list;
      $list = $(".js-sidebar-cast-lists").find(".js-lists");
      if ($list.length > 0) {
        return Sortable.create($list.get(0), {
          handle: ".js-handle",
          draggable: ".js-list",
          onSort: function() {
            return ExtraSidebarCastList.saveSort();
          }
        });
      }
    };

    ExtraSidebarCastList.saveSort = function() {
      var order;
      order = _.map($(".js-sidebar-cast-lists").find(".js-list"), function(e) {
        return $(e).data('cast-list');
      });
      return $.ajax({
        method: "post",
        url: "/admin/api/cast_lists/quick_list_order",
        data: {
          ids: order
        },
        success: function(data) {
          return ExtraSidebarCastList.setOrder(data.order);
        }
      });
    };

    ExtraSidebarCastList.setOrder = function(order) {
      var $list, newList;
      $list = $(".js-sidebar-cast-lists").find(".js-lists");
      newList = _.map(order, function(id) {
        return $list.find("[data-cast-list=" + id + "]").get(0);
      });
      $list.html("");
      _.each(newList, function(item) {
        return $list.append(item);
      });
      return $(".js-extra-card").each(function() {
        $list = $(this).find(".js-cast-lists");
        newList = _.map(order, function(id) {
          return $list.find("[data-cast-list=" + id + "]").get(0);
        });
        $list.html("");
        return _.each(newList, function(item) {
          return $list.append(item);
        });
      });
    };

    ExtraSidebarCastList.destroy = function(e) {
      var $list, id;
      e.preventDefault();
      $list = $(this).closest(".js-list");
      id = $list.data('cast-list');
      return $.ajax({
        method: "post",
        url: "/admin/api/cast_lists/" + id + "/remove_from_quick_list",
        dataType: "json",
        success: function() {
          $list.remove();
          $(".js-extra-card").find("[data-cast-list=" + id + "]").remove();
          ExtraSidebarCastList.updateBadge();
          return ExtraCard.updateBadges();
        }
      });
    };

    ExtraSidebarCastList.enableAddSelect = function() {
      return $(".js-sidebar-cast-lists").find(".js-select").select2({
        theme: "bootstrap",
        placeholder: "Find a cast list...",
        ajax: {
          url: "/admin/api/cast_lists/search",
          delay: 250,
          processResults: function(data, params) {
            return {
              results: data
            };
          }
        }
      }).on('select2:select', function(evt) {
        $(evt.target).val(0).trigger('change');
        return $.ajax({
          method: "post",
          url: "/admin/api/cast_lists/" + evt.params.data.id + "/add_to_quick_list",
          dataType: "json",
          success: function(data) {
            var $badge, $cast, $destroy, $li, $reorder, order;
            if ($(".js-sidebar-cast-lists").find(".js-list[data-cast-list=" + data.id + "]").length > 0) {
              $(".js-sidebar-cast-lists").find(".js-list[data-cast-list=" + data.id + "]").prependTo($(".js-sidebar-cast-lists").find(".js-lists"));
              order = _.map($(".js-sidebar-cast-lists").find(".js-list"), function(e) {
                return $(e).data('cast-list');
              });
              order.push('scratch');
              ExtraSidebarCastList.setOrder(order);
              return;
            }
            $li = $("<li />").addClass("list-item").addClass("js-list").attr('data-cast-list', data.id);
            $destroy = $("<div class='destroy js-destroy'><i class='fa fa-times'></i></div>");
            $reorder = $("<div class='reorder js-handle'><i class='fa fa-reorder'></i></div>");
            $badge = $("<div class='badge pull-right bold' />").html(data.extra_ids.length);
            $cast = $("<a />").html(data.name).prop('href', data.url);
            $li.append($destroy).append($cast).append($badge).append($reorder);
            $(".js-sidebar-cast-lists").find(".js-lists").prepend($li);
            ExtraSidebarCastList.updateBadge();
            return ExtraCard.addToQuickList(data);
          }
        });
      });
    };

    ExtraSidebarCastList.decorate = function() {
      ExtraSidebarCastList.enableSort();
      ExtraSidebarCastList.enableAddSelect();
      ExtraSidebarCastList.updateBadge();
      $(".js-sidebar-cast-lists").on('click', '.js-edit', ExtraSidebarCastList.toggleEditMode);
      return $(".js-sidebar-cast-lists").on('click', '.js-destroy', ExtraSidebarCastList.destroy);
    };

    return ExtraSidebarCastList;

  })();

}).call(this);