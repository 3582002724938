//= require jquery_ujs

import Rails from '@rails/ujs';
try {
  Rails.start();
} catch (e) {
  console.log(e);
}

import "@/javascripts/admin/app_list"
import "@/javascripts/admin/bxslider"
import "@/javascripts/admin/call-list"
import "@/javascripts/admin/cast-list-extras"
import "@/javascripts/admin/cast-list-search"
import "@/javascripts/admin/date-input"
import "@/javascripts/admin/document"
import "@/javascripts/admin/email-template"
import "@/javascripts/admin/extra-approval"
import "@/javascripts/admin/extra-card"
import "@/javascripts/admin/extra-form"
import "@/javascripts/admin/extra-message"
import "@/javascripts/admin/extra-profile"
import "@/javascripts/admin/extra-search"
import "@/javascripts/admin/nested_form"
import "@/javascripts/admin/notification"
import "@/javascripts/admin/payment"
import "@/javascripts/admin/photo"
import "@/javascripts/admin/production-rates"
import "@/javascripts/admin/sidebar-cast-list"
import "@/javascripts/admin/sidebar-scratch"
import "@/javascripts/admin/survey"
import "@/javascripts/admin/live_search"
import "@/javascripts/admin/private_upload"

import "@/javascripts/message"

import "@/javascripts/metronic/global/scripts/app"
import "@/javascripts/metronic/layouts/layout3/scripts/layout"
import "@/javascripts/metronic/layouts/global/scripts/quick-sidebar"

(function() {
  var dropMarker, dropRadius, map;

  map = null;

  dropMarker = null;

  dropRadius = null;

  jQuery(function() {
    return $(function() {
      window.ExtraCard.setup();
      window.ExtraSidebarCastList.setup();
      window.ExtraSidebarScratch.setup();
      window.ExtraSearch.setup(map, dropMarker, dropRadius);
      window.ExtraDateInput.setup();
      window.ExtraMessage.setup();
      window.ExtraProfile.setup();
      window.CastListExtras.setup();
      window.CastListSearch.setup();
      window.ExtraForm.setup();
      window.Photo.setup();
      window.Document.setup();
      window.ExtraApproval.setup();
      window.ProductionRates.setup();
      window.EmailTemplate.setup();
      window.Message.setup();
      window.Notification.setup();
      window.Payment.setup();
      window.AppList.setup();
      window.CallList.setup();
      window.LiveSearch.setup();
      window.PrivateUpload.setup();
      $('.cast-list-table table').stickyTableHeaders({
        fixedOffset: 51
      });
      $('.daily-lists-table table').stickyTableHeaders({
        fixedOffset: 51
      });
      $(".js-sticky-alpha").sticky({
        topSpacing: 50
      });
      $(".alphabet").on('click', 'a', function(e) {
        var id;
        e.preventDefault();
        id = $(this).attr("href");
        return $("html,body").animate({
          scrollTop: $("" + id).offset().top - 100
        }, 500);
      });
      $(".msg-tooltip").webuiPopover({
        trigger: "hover"
      });
      $.ajaxSetup({
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
      });
      $(".js-maxlength").maxlength({
        threshold: 10,
        alwaysShow: true
      });
      $(".js-select-2-std").select2({
        theme: "bootstrap",
        selectOnClose: true
      });
      $(document).on("nested:fieldAdded", function() {
        return $(this).find("select").select2({
          theme: "bootstrap",
          placeholder: "Select...",
          allowClear: true
        });
      });
      $(document).on('change', '.js-message-question-select', function() {
        if ($(this).val() === "multiple_choice" || $(this).val() === "single_choice") {
          return $(this).closest(".fields").find(".js-message-question-options").show();
        } else {
          return $(this).closest(".fields").find(".js-message-question-options").hide();
        }
      });
      return $(document).on('click', '.js-recipient', function(e) {
        var extras;
        e.preventDefault();
        $(this).toggleClass("selected");
        extras = _.map($(".js-recipient.selected"), function(v) {
          return $(v).data("extra");
        });
        return $(".js-message-extras").val(extras.join(","));
      });
    });
  });

}).call(this);
