(function() {
  window.Payment = (function() {
    function Payment() {}

    Payment.setup = function() {
      return Payment.decorate();
    };

    Payment.decorate = function() {
      return $(".js-payment-week").on('click', function(e) {
        var $row;
        e.preventDefault();
        $row = $(this).closest("tr");
        if ($row.next().hasClass("-week")) {
          return;
        }
        return $.ajax({
          url: $(this).prop('href'),
          method: "get",
          dataType: "html",
          success: function(data) {
            return $row.after(data);
          }
        });
      });
    };

    return Payment;

  })();

}).call(this);