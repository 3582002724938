(function() {
  window.CastListSearch = (function() {
    function CastListSearch() {}

    CastListSearch.map = null;

    CastListSearch.dropMarker = null;

    CastListSearch.dropRadius = null;

    CastListSearch.setup = function() {
      return CastListSearch.decorate();
    };

    CastListSearch.toggleFilter = function(e) {
      e.preventDefault();
      return $(this).closest(".filter").toggleClass("active");
    };

    CastListSearch.decorate = function() {
      return $(".js-cast-list-search-filter").on('click', '.js-toggle-filter', ExtraSearch.toggleFilter);
    };

    return CastListSearch;

  })();

}).call(this);