(function() {
  window.EmailTemplate = (function() {
    function EmailTemplate() {}

    EmailTemplate.setup = function() {
      return EmailTemplate.decorate();
    };

    EmailTemplate.decorate = function() {
      var fullbuttons;
      fullbuttons = ["bold", "italic", "underline", "paragraphFormat", "|", "color", "formatOL", "formatUL", "|", "insertLink", "insertImage", "insertFile", "|", "undo", "redo", "clearFormatting", "html"];
      return $(".fr-standard").froalaEditor({
        key: 'jemA-21vbzD-11yE4cJ-7i==',
        toolbarInline: false,
        heightMin: 100,
        toolbarButtons: fullbuttons,
        toolbarButtonsXS: fullbuttons,
        toolbarButtonsSM: fullbuttons,
        toolbarButtonsMD: fullbuttons,
        imageDefaultWidth: 0,
        imageEditButtons: ['imageReplace', 'imageRemove', 'imageAlt', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove'],
        fileUploadURL: "/admin/api/assets/file",
        imageUploadURL: "/admin/api/assets/image",
        videoResize: false,
        videoEditButtons: ['videoDisplay', 'videoRemove'],
        paragraphFormat: {
          N: "Normal",
          H3: "Heading"
        }
      });
    };

    return EmailTemplate;

  })();

}).call(this);