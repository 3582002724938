(function() {
  window.ExtraProfile = (function() {
    function ExtraProfile() {}

    ExtraProfile.setup = function() {
      return ExtraProfile.decorate();
    };

    ExtraProfile.displayMap = function() {
      var $map, address, geocoder, lat, lng;
      if ($(".js-extra-profile").find(".js-extra-map").length > 0) {
        $map = $(".js-extra-profile").find(".js-extra-map");
        lat = parseFloat($map.data("lat"));
        lng = parseFloat($map.data("lng"));
        address = $map.data("address");
        if (isNaN(lat)) {
          geocoder = new google.maps.Geocoder();
          return geocoder.geocode({
            address: address
          }, function(results, status) {
            var location;
            if (status === 'OK') {
              location = results[0].geometry.location;
              console.log(location.lat(), location.lng());
              return ExtraProfile.showMap(location.lat(), location.lng());
            }
          });
        } else {
          return ExtraProfile.showMap(lat, lng);
        }
      }
    };

    ExtraProfile.showMap = function(lat, lng) {
      var $map, marker, smallMap;
      if (!isNaN(lat)) {
        $map = $(".js-extra-profile").find(".js-extra-map");
        smallMap = new google.maps.Map($map.get(0), {
          zoom: 8,
          center: {
            lng: lng,
            lat: lat
          }
        });
        return marker = new google.maps.Marker({
          position: {
            lng: lng,
            lat: lat
          },
          map: smallMap
        });
      }
    };

    ExtraProfile.decorate = function() {
      ExtraProfile.displayMap();
      $(".js-extra-profile").find(".js-zoom").zoom();
      $(".js-extra-profile-slider").each(function() {
        return $(this).bxSlider({
          responsive: true,
          pager: false,
          auto: true
        });
      });
      $(".js-things-to-do").magnificPopup({
        type: "image",
        delegate: "a.js-photo-view",
        gallery: {
          enabled: true
        }
      });
      $(".js-photos-lightbox").magnificPopup({
        type: "image",
        delegate: "a.photo-card-image",
        gallery: {
          enabled: true
        }
      });
      $(document).on('click', '.js-extra-call', function(e) {
        var $this;
        e.preventDefault();
        $this = $(this);
        return $.ajax({
          url: $this.attr('href'),
          type: 'GET',
          dataType: 'json',
          success: function(data) {
            return $this.html("Dialing...");
          }
        });
      });
      $(".js-extra-profile").on('click', '.js-photo-reject', function(e) {
        var $link, $reasons;
        e.preventDefault();
        $link = $(this);
        $reasons = $("#photo_reasons");
        $reasons.find("form").prop("action", $link.prop("href"));
        return $.magnificPopup.open({
          items: {
            src: $reasons,
            type: "inline"
          },
          cache: false
        });
      });
      $(".js-extra-profile").on('click', ".js-document-reject", function(e) {
        var $link, $reasons;
        e.preventDefault();
        $link = $(this);
        if ($link.data("type") === "access_ni") {
          $reasons = $("#cert_reasons");
        } else {
          $reasons = $("#identification_reasons");
        }
        $reasons.find("form").prop("action", $link.prop("href"));
        return $.magnificPopup.open({
          items: {
            src: $reasons,
            type: "inline"
          },
          cache: false
        });
      });
      return $(".js-extra-profile").on('click', ".js-document-approve", function(e) {
        var $accessNi, $link;
        $link = $(this);
        if ($link.data("type") === "access_ni") {
          e.preventDefault();
          $accessNi = $("#access_ni_cert");
          $accessNi.find("form").prop("action", $link.prop("href"));
          $accessNi.find("input.form-control").val($link.data('issued'));
          return $.magnificPopup.open({
            items: {
              src: $accessNi,
              type: "inline"
            },
            cache: false
          });
        } else {

        }
      });
    };

    return ExtraProfile;

  })();

}).call(this);