(function() {
  window.AppList = (function() {
    function AppList() {}

    AppList.setup = function() {
      return AppList.decorate();
    };

    AppList.decorate = function() {
      return $("#app_list").each(function() {
        var list;
        return list = App.cable.subscriptions.create({
          channel: "ListChannel",
          id: $(this).data("channel")
        }, {
          connected: function() {},
          disconnected: function() {},
          received: function(data) {
            var $row;
            if (data.model === "bookings") {
              $row = $("#booking_" + data.data.id);
              if (data.data.sign_in_at != null) {
                $row.find(".js-sign-in").text(moment(data.data.sign_in_at).format("hh:mm A")).addClass("tick");
              } else {
                $row.find(".js-sign-in").text("").removeClass("tick");
              }
              if (data.data.sign_out_at != null) {
                $row.find(".js-sign-out").text(moment(data.data.sign_out_at).format("hh:mm A")).addClass("tick");
              } else {
                $row.find(".js-sign-out").text("").removeClass("tick");
              }
              if (data.data.aasm_state === "no_show") {
                $row.addClass("-no-show");
                return $row.find(".js-sign-in").text("No Show");
              } else {
                return $row.removeClass("-no-show");
              }
            }
          }
        });
      });
    };

    return AppList;

  })();

}).call(this);