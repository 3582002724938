(function() {
  window.Document = (function() {
    function Document() {}

    Document.setup = function() {
      return Document.decorate();
    };

    Document.decorate = function() {
      $(".js-document-form").on('click', '.js-document-form-type', function(e) {
        if ($(this).val() === "right_to_work") {
          $(".js-document-form").find(".js-document-form-expiry").show();
        } else {
          $(".js-document-form").find(".js-document-form-expiry").hide();
        }
        if ($(this).val() === "access_ni") {
          $(".js-document-form").find(".js-document-form-issued").show();
        } else {
          $(".js-document-form").find(".js-document-form-issued").hide();
        }
        if ($(this).val() === "release" || $(this).val() === "other") {
          $(".js-document-form").find(".js-document-form-production").show();
        } else {
          $(".js-document-form").find(".js-document-form-production").hide();
        }
        if ($(this).val() === "other") {
          $(".js-document-form").find(".js-document-form-visible").show();
          return $(".js-document-form").find(".js-document-form-name").show();
        } else {
          $(".js-document-form").find(".js-document-form-visible").hide();
          return $(".js-document-form").find(".js-document-form-name").hide();
        }
      });
      $(".js-documents").on('click', '.js-document-reject', function(e) {
        var $link, $reasons;
        e.preventDefault();
        $link = $(this);
        if ($link.data("type") === "access_ni") {
          $reasons = $("#cert_reasons");
        } else {
          $reasons = $("#identification_reasons");
        }
        $reasons.addClass("js-document-reasons");
        $reasons.find("form").prop("action", $link.prop("href"));
        $reasons.find("input").prop('checked', '');
        $reasons.find("input[type='submit']").prop('disabled', '');
        return $.magnificPopup.open({
          items: {
            src: $reasons,
            type: "inline"
          },
          cache: false
        });
      });
      $(".js-documents").on('click', '.js-document-approve', function(e) {
        var $accessNi, $link;
        e.preventDefault();
        $link = $(this);
        if ($link.data("type") === "access_ni") {
          e.preventDefault();
          $accessNi = $("#access_ni_cert").addClass("js-document-reasons");
          $accessNi.find("form").prop("action", $link.prop("href"));
          $accessNi.find("input.form-control").val($link.data('issued'));
          return $.magnificPopup.open({
            items: {
              src: $accessNi,
              type: "inline"
            },
            cache: false
          });
        } else {
          return $.ajax({
            method: "POST",
            url: $link.attr('href'),
            dataType: "json",
            success: function(data) {
              return $(".document-row[data-document=" + data.id + "]").remove();
            }
          });
        }
      });
      return $(document).on('submit', '.js-document-reasons form', function(e) {
        var $form;
        $form = $(this);
        $.ajax({
          method: "POST",
          url: $form.attr('action'),
          dataType: "json",
          data: $form.serialize(),
          success: function(data) {
            $(".document-row[data-document=" + data.id + "]").remove();
            return $.magnificPopup.close();
          }
        });
        e.preventDefault();
        return false;
      });
    };

    return Document;

  })();

}).call(this);