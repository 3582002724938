(function() {
  window.Notification = (function() {
    function Notification() {}

    Notification.setup = function() {
      return Notification.decorate();
    };

    Notification.decorate = function() {
      $(".js-header-notification-li").on('show.bs.dropdown', function(e) {
        return $.ajax({
          url: "/admin/notifications/popup",
          method: "get",
          dataType: "html",
          success: function(data) {
            $(".js-header-notification-menu").html(data);
            return $(".scroller").slimScroll();
          }
        });
      });
      return $(".js-notification-dismiss").on('click', function(e) {
        e.preventDefault();
        return $.ajax({
          url: $(this).prop('href'),
          method: "get",
          dataType: "json",
          success: function(data) {
            $.each(data.ids, function(i, e) {
              return $(".js-notification[data-notification=" + e + "]").fadeOut();
            });
            return $(".js-header-notification").html(data.count);
          }
        });
      });
    };

    return Notification;

  })();

}).call(this);