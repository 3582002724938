(function() {
  window.ExtraForm = (function() {
    function ExtraForm() {}

    ExtraForm.setup = function() {
      return ExtraForm.decorate();
    };

    ExtraForm.isAdult = function() {
      var birthday;
      birthday = moment($(".js-extra-form").find(".js-dob").datepicker('getDate'));
      return (birthday != null) && moment().diff(birthday, 'years') >= 18;
    };

    ExtraForm.isChild = function() {
      var birthday;
      birthday = moment($(".js-extra-form").find(".js-dob").datepicker('getDate'));
      return (birthday != null) && moment().diff(birthday, 'years') < 18;
    };

    ExtraForm.isMale = function() {
      return $(".js-extra-form").find(".js-gender").val() !== 'female';
    };

    ExtraForm.isFemale = function() {
      return $(".js-extra-form").find(".js-gender").val() !== 'male';
    };

    ExtraForm.updateProfile = function() {
      $(".js-extra-form").find(".-adult").hide();
      $(".js-extra-form").find(".-man").hide();
      $(".js-extra-form").find(".-woman").hide();
      $(".js-extra-form").find(".-child").hide();
      if (ExtraForm.isAdult()) {
        $(".js-extra-form").find(".-adult").show();
        if (ExtraForm.isMale()) {
          $(".js-extra-form").find(".-man").show();
        }
        if (ExtraForm.isFemale()) {
          return $(".js-extra-form").find(".-woman").show();
        }
      } else if (ExtraForm.isChild()) {
        return $(".js-extra-form").find(".-child").show();
      }
    };

    ExtraForm.decorate = function() {
      $(".js-extra-form").find("select").each(function() {
        if ($(this).closest(".js-simple-search").length > 0) {
          return $(this).select2({
            theme: "bootstrap",
            placeholder: "Select...",
            allowClear: true,
            matcher: function(term, text) {
              if (term.term != null) {
                if (text.text.toUpperCase().indexOf(term.term.toUpperCase()) === 0) {
                  return text;
                }
                return null;
              } else {
                return text;
              }
            }
          });
        } else {
          return $(this).select2({
            theme: "bootstrap",
            placeholder: "Select...",
            allowClear: true
          });
        }
      });
      $(".js-extra-form").find(".js-dob").datepicker().on('changeDate', function(e) {
        return ExtraForm.updateProfile();
      });
      return $(".js-extra-form").find(".js-gender").on('change', function(e) {
        return ExtraForm.updateProfile();
      });
    };

    return ExtraForm;

  })();

}).call(this);