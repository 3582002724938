(function() {
  window.PrivateUpload = (function() {
    function PrivateUpload() {}

    PrivateUpload.setup = function() {
      return PrivateUpload.decorate();
    };

    PrivateUpload.decorate = function() {
      console.log($(".js-preview-media").length)
      $(".js-preview-media").fancybox()
    };

    return PrivateUpload;

  })();

}).call(this);